import { useCallback, useState } from "react";

import Cookies from "js-cookie";
import headerApi from "modules/Header/api/headerApi";
import useTooltipsStore from "modules/Header/store/useTooltipsStore";

const TOOLTIP_COOKIE_KEY = "tooltips";

export const useTooltipsData = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { setTooltips } = useTooltipsStore((state) => state);

  const fetchTooltips = useCallback(async () => {
    setLoading(true);
    setError(false);

    try {
      const tooltipsFromCookies = Cookies.get(TOOLTIP_COOKIE_KEY);
      const parsedTooltipsFromCookies = tooltipsFromCookies
        ? JSON.parse(tooltipsFromCookies)
        : [];

      const res = await headerApi.getTooltips();

      if (JSON.stringify(parsedTooltipsFromCookies) !== JSON.stringify(res)) {
        Cookies.set(TOOLTIP_COOKIE_KEY, JSON.stringify(res), {
          expires: 7,
        });
        setTooltips(res);
      } else {
        setTooltips(parsedTooltipsFromCookies);
      }
    } catch (err) {
      console.error(err);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [setTooltips]);

  return {
    loading,
    error,
    fetchTooltips,
  };
};
