import React, { FC, useEffect } from "react";

import { Box } from "ui/Box";
import { BreadCrumbs } from "../BreadCrumbs";
import { Filters } from "../Filters";

import { styles } from "./styles";

import { getBreadCrumbsList } from "modules/Header/helpers";
import { useTooltipsData } from "modules/Header/hooks/useTooltipsData";
import { useLocation } from "react-router-dom";

export interface HeaderProps {}

export const Header: FC<HeaderProps> = React.memo(() => {
  const location = useLocation();
  const links = getBreadCrumbsList(location.pathname);
  const { fetchTooltips } = useTooltipsData();

  useEffect(() => {
    fetchTooltips();
  }, [fetchTooltips]);

  return (
    <Box sx={styles.root}>
      <BreadCrumbs links={links} />
      <Filters />
    </Box>
  );
});
