import { create } from "zustand";
import Cookies from "js-cookie"; // Import for cookie handling

export interface TooltipsState {
  tooltips: { key: string; content: string }[];
  setTooltips: (tooltips: { key: string; content: string }[]) => void;
}

const TOOLTIP_COOKIE_KEY = "tooltips";

const useTooltipsStore = create<TooltipsState>()((set) => {
  const tooltipsFromCookies = Cookies.get(TOOLTIP_COOKIE_KEY);
  const initialTooltips = tooltipsFromCookies
    ? JSON.parse(tooltipsFromCookies)
    : [];

  return {
    tooltips: initialTooltips,
    setTooltips: (tooltips) => set({ tooltips }),
  };
});

export const getTooltipByKey = (title: string, key: string) => {
  const { tooltips } = useTooltipsStore.getState();
  const tooltip = tooltips.find((tooltip) => tooltip.key === key);
  return { title: title, subtitle: tooltip?.content || "" };
};

export default useTooltipsStore;
